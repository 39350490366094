<template>
    <div class="setting_wpr">
        <div class="form_grp">
            <div class="group_item">
                <label class="input_label">First Name</label>
                <div class="group-col-3">
                    <div class="field_wpr">
                        <multiselect v-model="filters.first_name.filter" v-bind="firstName"></multiselect>
                    </div>
                    <div class="field_wpr" v-if="filters.first_name.filter && filters.first_name.filter.length">
                        <input type="text" placeholder="Chris" v-model="filters.first_name.keyword" >
                    </div>
                </div>
            </div>
        </div>
        <div class="divider"><span>Or</span></div>
        <div class="form_grp">
            <div class="group_item">
                <label class="input_label">Last Name</label>
                <div class="group-col-3">
                    <div class="field_wpr">
                        <multiselect v-model="filters.last_name.filter" v-bind="lastName"></multiselect>
                    </div>
                    <div class="field_wpr" v-if="filters.last_name.filter && filters.last_name.filter.length">
                        <input type="text" placeholder="Damon" v-model="filters.last_name.keyword" >
                    </div>
                </div>
            </div>
        </div>
        <div class="form_grp">
            <div class="group_item">
                <label class="input_label">Date Joined</label>
                <div class="group-col-3">
                    <div class="field_wpr">
                        <multiselect v-model="filters.date_joined.filter" v-bind="dateJoined"></multiselect>
                    </div>
                    <div class="field_wpr" v-if="filters.date_joined.filter && filters.date_joined.filter.length">
                        <datepicker v-model="filters.date_joined.keyword" autoApply placeholder="MM/DD/YYYY" format="MM/dd/yyyy"></datepicker>
                    </div>
                </div>
            </div>
        </div>
        <div class="form_grp">
            <div class="group_item">
                <label class="input_label">Temperature</label>
                <div class="field_wpr">
                    <multiselect v-model="filters.temperature" v-bind="temperature" ></multiselect>
                </div>
            </div>
        </div>
        <div class="form_grp">
            <div class="group_item">
                <label class="input_label">Email Status</label>
                <div class="field_wpr">
                    <multiselect v-model="filters.email_status" v-bind="emailStatus" ></multiselect>
                </div>
            </div>
        </div>
        <div class="form_grp">
            <div class="group_item">
                <label class="input_label">Status</label>
                <div class="field_wpr">
                    <multiselect
                        v-model="filters.status"
                        :groups="true"
                        :searchable="true"
                        :options="statusesGroup"
                        valueProp="value"
                        label="title"
                        placeholder="Select status"
                        :group-select="false"
                        group-label="type"
                        group-options="items"
                        :group-hide-empty="true"
                        :loading="statusTabsLoader"
                    >
                        <template v-slot:grouplabel="{ group }">
                            <span class="status-tabs-header">{{ group.type }}</span>
                        </template>
                        <template v-slot:option="{ option }">
                             <span>{{ option.title }}</span>
                       </template>
                    </multiselect>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex'

    export default {
        name: 'Group Search Filter',

        data () {
            return {
                lastName: {
                    mode: 'single',
                    value: [],
                    options: [
                        { value: 'Starts with', label: 'Starts with' },
                        { value: 'Ends with', label: 'Ends with' },
                        { value: 'Contains', label: 'Contains' }
                    ],
                },
                firstName:{
                    mode: 'single',
                    value: [],
                    options: [
                        { value: 'Starts with', label: 'Starts with' },
                        { value: 'Ends with', label: 'Ends with' },
                        { value: 'Contains', label: 'Contains' }
                    ],
                },
                email:{
                    mode: 'single',
                    value: [],
                    options: [
                        { value: 'Starts with', label: 'Starts with' },
                        { value: 'Ends with', label: 'Ends with' },
                        { value: 'Contains', label: 'Contains' }
                    ],
                },
                phone:{
                    mode: 'single',
                    value: [],
                    options: [
                        { value: 'Starts with', label: 'Starts with' },
                        { value: 'Ends with', label: 'Ends with' },
                        { value: 'Contains', label: 'Contains' }
                    ],
                },
                dateJoined: {
                    mode: 'single',
                    value: [],
                    options: [
                        { value: 'Date is', label: 'Date is' },
                        { value: 'Date is not', label: 'Date is not' },
                        { value: 'Date is before', label: 'Date is before' },
                        { value: 'Date is after', label: 'Date is after' }
                    ],
                },
                temperature:{
                    mode: 'single',
                    value: [],
                    options: [
                    { value: 'cold', label: 'cold' },
                    { value: 'Warm', label: 'Warm' },
                    { value: 'Hot', label: 'Hot' }
                    ],
                },
                phoneStatus:{
                    mode: 'single',
                    value: [],
                    options: [
                    { value: 'Invalid', label: 'Invalid' },
                    { value: 'Missing', label: 'Missing' }
                    ],
                },
                emailStatus:{
                    mode: 'single',
                    value: [],
                    options: [
                    { value: 'Invalid', label: 'Invalid' },
                    { value: 'Missing', label: 'Missing' }
                    ],
                },
                filters: {
                    first_name: {
                        filter: '',
                        keyword: '',
                    },
                    last_name: {
                        filter: '',
                        keyword: '',
                    },
                    date_joined: {
                        filter: '',
                        keyword: '',
                    },
                    temperature: '',
                    email_status: '',
                    status: '',
                }
            }
        },

        props: {
            statusesGroup: [Object, Array]
        },

        computed: mapState({
            statusTabsLoader: state => state.contactModule.statusTabsLoader,
        }),
    }
</script>
